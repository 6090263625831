import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface MoveLowestAvailableFareUpPayload extends BaseFlightActionPayload {
  cabinCode: string;
  offset: number;
}

/**
 * This action is named 'closeLowestAvailableFare' on the back-end,
 * because technically that is what is happening to the flight.
 * But by closing the current LAF, the LAF moves up.
 */
export class MoveLowestAvailableFareUpAction implements FlightAction<MoveLowestAvailableFareUpPayload> {
  public actionType: FlightActionType = FlightActionType.closeLowestAvailableFare;
  public label = t('dynamic_filters.laf_n_up');
  public value = 1;
  public cabinCode: string;
  public showBulkAvailabilityWarning: boolean;

  constructor(cabinCode?: string, showBulkAvailabilityWarning = false) {
    this.cabinCode = cabinCode;
    this.showBulkAvailabilityWarning = showBulkAvailabilityWarning;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.moveLowestAvailableFareUp,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.closeLowestAvailableFare,
        field: FilterFieldField.moveLowestAvailableFareUp,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.moveLowestAvailableFareUp,
        uniqueField: this.cabinCode,
        value: this.value,
        componentName: ComponentName.MoveLafUpField,
        isValueValid: (value: any) => isValueNotEmpty(value),
        warning: this.showBulkAvailabilityWarning ? t('bulk_availability_change') : null,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: MoveLowestAvailableFareUpPayload): void {
    this.value = Math.abs(payload.offset);
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): MoveLowestAvailableFareUpPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      offset: this.value,
    };
  }
}
