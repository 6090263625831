import { PickupType } from '@/modules/shared/shared-contracts';

export enum PssName {
  Amadeus = '1a',
  Radixx = '1d',
  Sabre = '1s',
  Navitaire = '1n',
  Genius_TurSys = '1x',
  Pribas = '1y',
  Videcom = '1v',
}

export enum NestingType {
  Serial = 'serial',
  Net = 'net',
}

export type CabinCode =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';

export enum CabinName {
  Economy = 'Economy',
  BasicEconomy = 'Basic',
  PremiumEconomy = 'Premium',
  Business = 'Business',
  First = 'First',
}

export interface CabinStructure {
  code: CabinCode;
  name: CabinName;
  order: number;
  classes: ClassStructure[];
  opLevelAdjustmentIncrement?: number;
}

export enum DiscreteType {
  waitlist = 'waitlist',
  groupBooking = 'groupbooking',
  promotion = 'promotion',
}

export interface ClassStructure {
  code: string;
  order: number;
  discrete?: boolean;
  discreteType?: DiscreteType;
  bucketCode?: string;
  parentClassCode?: string;
  excludeInLAF?: boolean;
  subClass?: boolean;
}

export interface InventoryConfigurationProperties {
  cabins: CabinStructure[];
  pss: PssName;
  nesting: NestingType;
}

export interface ApplicationSettingsModel {
  baseCurrency: { code: string };
  flowFxProperties: FlowFxProperties;
  inventoryConfigurationProperties: InventoryConfigurationProperties;
  pickupLimits: PickupLimits;
  airlineProperties: AirlineProperties;
}

export interface AirlineProperties {
  timezoneId?: string;
}

export interface FlowFxProperties {
  performanceBandPickupConditionDayList: number[];
}

/**
 * The PickupLimits interface is used to define the maximum number of historical pickup days allowed for different types of pickups.
 * Each property in the interface corresponds to a different type of pickup, and the value is the maximum number of days back allowed for that type.
 */
export interface PickupLimits {
  [PickupType.booking]?: number;
  [PickupType.performanceBand]?: number;
  [PickupType.revenue]?: number;
}

export interface IApplicationService {
  getSettings(): Promise<ApplicationSettingsModel>;
}
