import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { ClassStructure } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { UpdateAuthorizationUnitsPayload } from '@/modules/flight-actions/actions/cabin-actions/update-authorization-units-action';
import { isArrayValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import {
  AvailabilityDistribution,
  BaseFlightActionPayload,
  ClassValue,
  DistributedClasses,
  FlightAction,
  FlightActionType,
  FlightActionUpdateType,
  PinState,
  TypedPinnableAdjustmentBulkUpdate,
} from '@/modules/flight-actions/api/flight-actions.contracts';
import { ClearableClasses, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { CabinService } from '@/modules/shared/services/cabin.service';
import { i18n } from '@/plugins/i18n';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

const { t } = i18n.global;

export interface UpdateProtectionAdjustingAuthorizationUnitsPayload extends BaseFlightActionPayload {
  cabinCode: string;
  updates: TypedPinnableAdjustmentBulkUpdate[];
}

export class UpdateProtectionAction implements FlightAction<UpdateProtectionAdjustingAuthorizationUnitsPayload> {
  public actionType: FlightActionType = FlightActionType.updateProtection;
  public label = t('dynamic_filters.change_pr');
  public value: DistributedClasses;
  public cabinCode: string;

  constructor(cabinCode?: string, value?: DistributedClasses) {
    this.cabinCode = cabinCode;

    const appSettingsStore = useAppSettingsStore();

    this.value = value ?? {
      distribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
      classes: CabinService.getCabinClasses(
        appSettingsStore.inventoryConfigurationProperties.pss,
        appSettingsStore.inventoryConfigurationProperties.cabins,
        cabinCode,
      ).map((cls: ClassStructure, index) => ({
        code: cls.code,
        order: index, // needed to get the cabin class
        value: null,
        pinned: PinState.NO_CHANGE,
        changed: null,
        clearValue: false,
        referencedClassCode: null,
        selectedUnit: [FlightActionUpdateType.PrBased],
        type: FlightActionUpdateType.PrBased,
      })),
    };
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.changePR,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.updateProtection,
        field: FilterFieldField.changePR,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.changePR,
        uniqueField: this.cabinCode,
        value: this.value,
        componentName: ComponentName.CustomInventoryManagementPinField,
        clearableClasses: ClearableClasses.onlyCabinClass,
        hasPin: true,
        isValueValid: (value: DistributedClasses) =>
          isArrayValueNotEmpty(value.classes.filter((cl) => cl.changed || cl.clearValue || cl.pinned !== PinState.NO_CHANGE)),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: UpdateAuthorizationUnitsPayload): void {
    const appSettingsStore = useAppSettingsStore();
    const classValues: ClassValue[] = CabinService.getCabinClasses(
      appSettingsStore.inventoryConfigurationProperties.pss,
      appSettingsStore.inventoryConfigurationProperties.cabins,
      payload.cabinCode,
    ).map((cls: ClassStructure, index) => {
      const classUpdate = payload.updates.find((c) => c.classCode === cls.code);

      return classUpdate
        ? {
            code: classUpdate.classCode,
            pinned: classUpdate.pinState,
            value: classUpdate.value,
            changed: isFinite(classUpdate.value) || classUpdate.pinState !== PinState.NO_CHANGE,
            clearValue: classUpdate.clearValue,
            order: index, // needed to get the cabin class
            referencedClassCode: classUpdate.referencedClassCode,
            selectedUnit: [classUpdate.type],
            type: classUpdate.type as FlightActionUpdateType,
          }
        : {
            code: cls.code,
            pinned: PinState.NO_CHANGE,
            value: null,
            changed: false,
            clearValue: false,
            order: index, // needed to get the cabin class
            selectedUnit: [FlightActionUpdateType.PrBased],
            type: FlightActionUpdateType.PrBased,
          };
    });

    this.value = {
      distribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
      classes: classValues,
    };
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): UpdateAuthorizationUnitsPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      updates: this.value.classes
        .filter((it) => it.value !== null || it.clearValue || it.pinned !== PinState.NO_CHANGE)
        .map(
          (classChange: ClassValue) =>
            ({
              classCode: classChange.code,
              value: classChange.value,
              referencedClassCode: classChange.referencedClassCode,
              type: classChange.type,
              pinState: classChange.pinned,
              clearValue: classChange.clearValue,
            }) as TypedPinnableAdjustmentBulkUpdate,
        ),
    };
  }
}
