import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';

export interface ChangeMultiLegUnbalancedAdjustmentPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: number;
  legNumber: number;
}

/**
 * This Action doesn't have a Cascader option, as it's only used in details and not in bulk,
 * since this is applied on a per-leg basis.
 */
export class ChangeMultiLegUnbalancedAdjustmentAction implements FlightAction<ChangeMultiLegUnbalancedAdjustmentPayload> {
  public actionType: FlightActionType = FlightActionType.changeMultiLegUnbalancedAdjustment;
  public cabinCode: string;
  public value: number;
  public legNumber: number;

  constructor(cabinCode?: string, unbalancedAdjustment?: number, legNumber?: number) {
    this.cabinCode = cabinCode;
    this.value = unbalancedAdjustment;
    this.legNumber = legNumber;
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: ChangeMultiLegUnbalancedAdjustmentPayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
    this.legNumber = payload.legNumber;
  }

  public getPayload(): ChangeMultiLegUnbalancedAdjustmentPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      legNumber: this.legNumber,
      value: this.value,
    };
  }
}
