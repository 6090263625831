import { isEmpty } from 'lodash-es';
import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { OptimisationTactic } from '@/models/OptimisationTactic';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { CascaderOptionValue } from '@/modules/flight-actions/actions/cabin-actions/add-optimization-profile-level-action';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions.contracts';

const { t } = i18n.global;

export interface AddShadowOptimizationProfileLevelPayload extends BaseFlightActionPayload {
  cabinCode: string;
  optimisationProfileLevelId?: number;
}

export interface ShadowOptimizationProfileFieldPayload {
  optimizationProfile: CascaderOptionValue[];
}

/**
 * This action is named 'addShadowOptimizationProfileLevel' or 'removeShadowOptimizationProfileLevel' on the back-end,
 * because technically that is what is happening, based on the user's selection.
 * By selecting a shadow profile level, the user sets a shadow optimization profile level.
 * By selecting manual, the user removes the optimization profile level. (making the optimization tactic implicitly 'Manual', as there is no automated tactic selected)
 */
export class AddShadowOptimizationProfileLevelAction implements FlightAction<AddShadowOptimizationProfileLevelPayload> {
  public actionType: FlightActionType = FlightActionType.addShadowOptimisationProfileLevel;
  public label = t('dynamic_filters.change_shadow_optimisation_tactic');
  public value: CascaderOptionValue[] | CascaderOptionValue = [];
  public cabinCode?: string;
  public optimizationProfileLevelId?: number;

  constructor(cabinCode?: string, optimizationProfileLevelId?: number) {
    if (optimizationProfileLevelId) {
      this.optimizationProfileLevelId = optimizationProfileLevelId;
    } else {
      this.actionType = FlightActionType.removeShadowOptimisationProfileLevel;
    }
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.changeShadowOptimisationTactic,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: this.actionType,
        field: FilterFieldField.changeShadowOptimisationTactic,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.changeShadowOptimisationTactic,
        uniqueField: this.cabinCode,
        componentName: ComponentName.OptimisationProfileField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/SetOptimizationTacticActionValueRenderer.vue'),
        ),
        value: { optimizationProfile: this.value } as ShadowOptimizationProfileFieldPayload,
        isValueValid: (value: ShadowOptimizationProfileFieldPayload) => !isEmpty(value.optimizationProfile),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const payload = action.value as ShadowOptimizationProfileFieldPayload;
    this.actionType =
      payload.optimizationProfile[0] !== OptimisationTactic.Manual
        ? FlightActionType.addShadowOptimisationProfileLevel
        : FlightActionType.removeShadowOptimisationProfileLevel;

    if (this.actionType === FlightActionType.addShadowOptimisationProfileLevel) {
      this.optimizationProfileLevelId = payload.optimizationProfile[payload.optimizationProfile.length - 1] as number;
    }
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: AddShadowOptimizationProfileLevelPayload): void {
    // If an optimisationProfileLevelId is set, assume an add action
    if (payload.optimisationProfileLevelId) {
      // TODO: BYOR would probably create merge conflicts here if this was still the add-optimization-profile file (without shadow)
      // TODO: When merged, make sure this remains working with BYOR
      this.value = [OptimisationTactic.Profiles, payload.optimisationProfileLevelId];
      this.actionType = FlightActionType.addShadowOptimisationProfileLevel;
      this.optimizationProfileLevelId = payload.optimisationProfileLevelId;
    } else {
      this.value = OptimisationTactic.Manual;
      this.actionType = FlightActionType.removeShadowOptimisationProfileLevel;
      this.optimizationProfileLevelId = undefined;
    }
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): AddShadowOptimizationProfileLevelPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      optimisationProfileLevelId: this.optimizationProfileLevelId,
    };
  }
}
