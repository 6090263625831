import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface UpdateOverbookingAutopilotPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: boolean;
  legNumber?: number;
}

export class UpdateOverbookingAutopilotAction implements FlightAction<UpdateOverbookingAutopilotPayload> {
  public actionType: FlightActionType;
  public label = t('control.bulk_actions.set_overbooking_autopilot');
  public value: boolean;
  public cabinCode: string;
  public legNumber: number | undefined;
  public fieldType: FilterFieldField = FilterFieldField.overbookingAutopilot;

  constructor(cabinCode?: string, legNumber?: number) {
    this.cabinCode = cabinCode ?? 'Y';
    this.legNumber = legNumber;
    this.value = false;
    this.setFlightActionType(legNumber);
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + this.fieldType,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: this.actionType,
        field: this.fieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.fieldType,
        uniqueField: this.cabinCode,
        componentName: ComponentName.OverbookingAutopilotField,
        value: {
          value: this.value,
          legNumber: this.legNumber,
        },
        isValueValid: (value: any) => isValueNotEmpty(value),
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/BooleanActionValueRenderer.vue'),
        ),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition<UpdateOverbookingAutopilotPayload>): void {
    const payload = action.value;

    if (payload) {
      this.value = payload.value;
      this.legNumber = payload.legNumber;
      this.setFlightActionType(payload.legNumber);
    }

    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: UpdateOverbookingAutopilotPayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
    this.legNumber = payload.legNumber;
    this.setFlightActionType(payload.legNumber);
  }

  public getPayload(): UpdateOverbookingAutopilotPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      legNumber: this.legNumber,
      value: !!this.value,
    };
  }

  private setFlightActionType(legNumber: number | undefined): void {
    this.actionType = legNumber ? FlightActionType.updateMultiLeggedOverbookingAutopilot : FlightActionType.updateOverbookingAutopilot;
  }
}
