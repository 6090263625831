import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { ClassStructure } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionConfigurationParams } from '@/modules/flight-actions/actions/definitions/flight-action-factory';
import { isArrayValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import {
  AdjustmentBulkUpdate,
  BaseFlightActionPayload,
  ClassValue,
  FlightAction,
  FlightActionType,
  PinState,
} from '@/modules/flight-actions/api/flight-actions.contracts';
import { ClearableClasses, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { CabinService } from '@/modules/shared/services/cabin.service';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface UpdateMinControlPayload extends BaseFlightActionPayload {
  cabinCode: string;
  updates: AdjustmentBulkUpdate[];
}

export class UpdateMinControlAction implements FlightAction<UpdateMinControlPayload> {
  public actionType: FlightActionType = FlightActionType.changeMinControl;
  public label = t('dynamic_filters.change_min_control');
  public cabinCode: string;
  public value: ClassValue[];

  private config: FlightActionConfigurationParams;

  constructor({ cabinCode, classes, config }: { cabinCode?: string; classes?: ClassValue[]; config: FlightActionConfigurationParams }) {
    this.cabinCode = cabinCode;
    this.config = config;

    if (classes) {
      this.value = classes;
    } else {
      this.value = CabinService.getCabinClasses(
        config.inventoryConfigurationProperties.pss,
        config.inventoryConfigurationProperties.cabins,
        this.cabinCode,
      ).map((cls: ClassStructure, index) => ({
        code: cls.code,
        order: index, // needed to get the cabin class
        value: null,
        pinned: PinState.NO_CHANGE,
        changed: null,
        clearValue: false,
        referencedClassCode: null,
      }));
    }
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.changeMinControl,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.changeMinControl,
        field: FilterFieldField.changeMinControl,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.changeMinControl,
        uniqueField: this.cabinCode,
        value: this.value,
        componentName: ComponentName.CustomInventoryManagementField,
        clearableClasses: ClearableClasses.all,
        isValueValid: (value: any[]) => isArrayValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as string;
    this.value = action.value;
  }

  public getPayload(): UpdateMinControlPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      updates: this.value
        .filter((it) => it.value !== null || it.clearValue)
        .map((classChange: ClassValue) => ({
          classCode: classChange.code,
          value: classChange.value,
          clearValue: classChange.clearValue,
        })),
    };
  }

  public setPayload(payload: UpdateMinControlPayload) {
    this.cabinCode = payload.cabinCode;
    this.value = CabinService.getCabinClasses(
      this.config.inventoryConfigurationProperties.pss,
      this.config.inventoryConfigurationProperties.cabins,
      payload.cabinCode,
    ).map<ClassValue>((cls: ClassStructure, index) => {
      const classUpdate = payload.updates.find((c) => c.classCode === cls.code);
      if (!classUpdate) {
        return {
          code: cls.code,
          value: null,
          order: index, // needed to get the cabin class
          changed: false,
          clearValue: false,
          pinned: undefined,
        };
      }
      return {
        code: classUpdate.classCode,
        value: classUpdate.value,
        order: index, // needed to get the cabin class
        changed: isFinite(classUpdate.value),
        clearValue: classUpdate.clearValue,
        pinned: undefined,
      };
    });
  }
}
