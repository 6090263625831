<template>
  <div>
    <span class="inline-block w-16" :data-test="isDiscrete ? 'cabin-class-discrete-value' : 'cabin-class-value'">
      {{ classCode }}
    </span>

    <span class="text-lightBlue text-2xs font-semibold uppercase">
      {{ discreteClass }}
    </span>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { defineComponent } from 'vue';

import { CabinStructure } from '@/modules/api/application/application-contracts';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

export default defineComponent({
  props: {
    classCode: {
      type: String,
      required: true,
    },
    cabinCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useAppSettingsStore, ['inventoryConfigurationProperties']),
    cabin(): CabinStructure {
      return this.inventoryConfigurationProperties.cabins.find((cabin) => cabin.code === this.cabinCode);
    },
    isDiscrete(): boolean {
      return this.cabin.classes.find((cls) => cls.code === this.classCode)?.discrete;
    },
    discreteClass(): string {
      return this.isDiscrete ? this.$t('discrete') : '';
    },
  },
});
</script>
