import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface MoveOptimizationProfileLevelPayload extends BaseFlightActionPayload {
  cabinCode: string;
  offset: number;
  applyRecommendedAU: boolean;
}

export interface MoveOptimizationProfileFieldPayload {
  levelMutation: number;
  applyRecommendedAUs: boolean;
}

/**
 * This action is named 'moveOptimizationProfileLevel' on the back-end,
 * because technically that is what is happening to the flight's cabin.
 * But by setting the offset, it's moving the profile level up or down.
 * Those are two different options for the user in flight actions.
 */
export class MoveOptimizationProfileLevelAction implements FlightAction<MoveOptimizationProfileLevelPayload> {
  public actionType: FlightActionType = FlightActionType.moveOptimizationProfileLevel;
  public value = 1;
  public cabinCode: string;
  public applyRecommendedAU: boolean;
  public fieldType: FilterFieldField;

  constructor(levelMutation = 1, applyRecommendedAU = true, cabinCode?: string) {
    this.setMutation(levelMutation);
    this.applyRecommendedAU = applyRecommendedAU;
    this.cabinCode = cabinCode;
  }

  get label() {
    return this.fieldType === FilterFieldField.moveOptimizationProfileLevelUp
      ? t('dynamic_filters.opt_profile_level_n_up')
      : t('dynamic_filters.opt_profile_level_n_down');
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + this.fieldType,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.moveOptimizationProfileLevel,
        field: this.fieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.fieldType,
        uniqueField: this.cabinCode,
        componentName: ComponentName.MoveOptimizationProfileField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/MoveOptimizationLevelActionValueRenderer.vue'),
        ),
        value: {
          levelMutation: this.value,
          applyRecommendedAUs: this.applyRecommendedAU,
        } as MoveOptimizationProfileFieldPayload,
        isValueValid: (value: MoveOptimizationProfileFieldPayload) => isFinite(value.levelMutation),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const fieldPayload = action.value as MoveOptimizationProfileFieldPayload;
    this.value = fieldPayload.levelMutation;
    this.fieldType = action.field as FilterFieldField;
    this.cabinCode = action.uniqueField as string;
    this.applyRecommendedAU = fieldPayload.applyRecommendedAUs;
  }

  public setPayload(payload: MoveOptimizationProfileLevelPayload): void {
    this.setMutation(payload.offset);
    this.cabinCode = payload.cabinCode;
    this.applyRecommendedAU = payload.applyRecommendedAU;
  }

  public getPayload(): MoveOptimizationProfileLevelPayload {
    // Convert number to negative if moving level down
    const offset = this.fieldType === FilterFieldField.moveOptimizationProfileLevelDown ? this.value * -1 : this.value;

    return {
      actionType: this.actionType,
      offset: offset,
      cabinCode: this.cabinCode,
      applyRecommendedAU: this.applyRecommendedAU,
    };
  }

  /**
   * setMutation sets the correct state for the action, as a negative value is displayed to the user as a 'down' option, whilst positive is the 'up' option.
   * This must be reflected in the UI. The offset will always be displayed as a positive number.
   * @param levelMutation the relative amount of levels the optimization profile will move, can be negative
   */
  private setMutation(levelMutation: number) {
    this.fieldType =
      levelMutation < 0 ? FilterFieldField.moveOptimizationProfileLevelDown : FilterFieldField.moveOptimizationProfileLevelUp;
    this.value = Math.abs(levelMutation);
  }
}
