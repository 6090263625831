import { isEmpty } from 'lodash-es';
import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { OptimisationTactic } from '@/models/OptimisationTactic';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions.contracts';

const { t } = i18n.global;

export interface AddOptimizationProfileLevelPayload extends BaseFlightActionPayload {
  cabinCode: string;
  optimisationProfileLevelId?: number;
  applyRecommendedAU: boolean;
}

export type CascaderOptionValue = string | number;

export interface OptimizationProfileFieldPayload {
  optimizationProfile: CascaderOptionValue[];
  applyRecommendedAUs: boolean;
}

/**
 * This action is named 'addOptimizationProfileLevel' or 'removeOptimizationProfileLevel' on the back-end,
 * because technically that is what is happening, based on the user's selection.
 * By selecting a profile level, the user sets an optimization profile level.
 * By selecting manual, the user removes the optimization profile level. (making the optimization tactic implicitly 'Manual', as there is no automated tactic selected)
 */
export class AddOptimizationProfileLevelAction implements FlightAction<AddOptimizationProfileLevelPayload> {
  public actionType: FlightActionType = FlightActionType.addOptimisationProfileLevel;
  public label = t('dynamic_filters.change_optimisation_tactic');
  public value: CascaderOptionValue[] | CascaderOptionValue = [];
  public cabinCode?: string;
  public applyRecommendedAUs: boolean;
  public optimizationProfileLevelId?: number;

  constructor(cabinCode?: string, applyRecommendedAUs = true, optimizationProfileLevelId?: number) {
    if (optimizationProfileLevelId) {
      this.optimizationProfileLevelId = optimizationProfileLevelId;
    } else {
      this.actionType = FlightActionType.removeOptimisationProfileLevel;
    }
    this.cabinCode = cabinCode;
    this.applyRecommendedAUs = applyRecommendedAUs;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.changeOptimisationTactic,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: this.actionType,
        field: FilterFieldField.changeOptimisationTactic,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.changeOptimisationTactic,
        uniqueField: this.cabinCode,
        componentName: ComponentName.OptimisationProfileField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/SetOptimizationTacticActionValueRenderer.vue'),
        ),
        value: { applyRecommendedAUs: this.applyRecommendedAUs, optimizationProfile: this.value } as OptimizationProfileFieldPayload,
        isValueValid: (value: OptimizationProfileFieldPayload) => !isEmpty(value.optimizationProfile),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const payload = action.value as OptimizationProfileFieldPayload;
    this.actionType =
      payload.optimizationProfile[0] !== OptimisationTactic.Manual
        ? FlightActionType.addOptimisationProfileLevel
        : FlightActionType.removeOptimisationProfileLevel;

    if (this.actionType === FlightActionType.addOptimisationProfileLevel) {
      this.optimizationProfileLevelId = payload.optimizationProfile[payload.optimizationProfile.length - 1] as number;
    }

    this.applyRecommendedAUs = payload.applyRecommendedAUs;
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: AddOptimizationProfileLevelPayload): void {
    // If an optimisationProfileLevelId is set, assume an add action
    if (payload.optimisationProfileLevelId) {
      this.value = [OptimisationTactic.Profiles, payload.optimisationProfileLevelId];
      this.actionType = FlightActionType.addOptimisationProfileLevel;
      this.optimizationProfileLevelId = payload.optimisationProfileLevelId;
    } else {
      this.value = OptimisationTactic.Manual;
      this.actionType = FlightActionType.removeOptimisationProfileLevel;
      this.optimizationProfileLevelId = undefined;
    }
    this.applyRecommendedAUs = payload.applyRecommendedAU;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): AddOptimizationProfileLevelPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      applyRecommendedAU: this.applyRecommendedAUs,
      optimisationProfileLevelId: this.optimizationProfileLevelId,
    };
  }
}
