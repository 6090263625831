import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionConfigurationParams } from '@/modules/flight-actions/actions/definitions/flight-action-factory';
import { isArrayValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import {
  AvailabilityDistribution,
  BaseFlightActionPayload,
  FlightAction,
  FlightActionType,
} from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface MoveLowestAvailableFareDownPayload extends BaseFlightActionPayload {
  cabinCode: string;
  offset: number;
  seatsAvailable: number;
  availabilityDistribution: AvailabilityDistribution;
}

/**
 * [Offset, SeatsAvailable, Distribution]
 */
export type MoveLowestAvailableFareDownActionValueArray = [{ value: number }, { value: number }, { value: AvailabilityDistribution }];

/**
 * This action is named 'openSetSeatsLowestAvailableFare' on the back-end,
 * because technically that is what is happening to the flight.
 * But by opening seats on a lower class, the LAF moves down.
 */
export class MoveLowestAvailableFareDownAction implements FlightAction<MoveLowestAvailableFareDownPayload> {
  public actionType: FlightActionType = FlightActionType.openSetSeatsLowestAvailableFare;
  public label = t('dynamic_filters.laf_n_down');
  public value = 1;
  public seatsAvailable = 1;
  public cabinCode: string;
  public showBulkAvailabilityWarning: boolean;
  public availabilityDistribution: AvailabilityDistribution = AvailabilityDistribution.MAINTAIN_PROTECTIONS;

  constructor({ cabinCode, config }: { cabinCode?: string; config: FlightActionConfigurationParams }) {
    this.cabinCode = cabinCode;
    this.showBulkAvailabilityWarning = config.pssCapabilities.hasSegmentLimit;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.moveLowestAvailableFareDown,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.openSetSeatsLowestAvailableFare,
        field: FilterFieldField.moveLowestAvailableFareDown,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.moveLowestAvailableFareDown,
        uniqueField: this.cabinCode,
        value: [
          { value: this.value },
          { value: this.seatsAvailable },
          { value: this.availabilityDistribution },
        ] as MoveLowestAvailableFareDownActionValueArray,
        componentName: ComponentName.MoveLafField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/MoveLAFDownActionValueRenderer.vue'),
        ),
        isValueValid: (value: any[]) => isArrayValueNotEmpty(value),
        warning: this.showBulkAvailabilityWarning ? t('bulk_availability_change') : null,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const arrayValues = action.value as MoveLowestAvailableFareDownActionValueArray;
    this.value = arrayValues[0].value;
    this.seatsAvailable = arrayValues[1].value;
    this.availabilityDistribution = arrayValues[2].value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: MoveLowestAvailableFareDownPayload): void {
    this.value = Math.abs(payload.offset);
    this.seatsAvailable = payload.seatsAvailable;
    this.availabilityDistribution = payload.availabilityDistribution;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): MoveLowestAvailableFareDownPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      offset: this.value,
      seatsAvailable: this.seatsAvailable,
      availabilityDistribution: this.availabilityDistribution,
    };
  }
}
