import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface RemoveCabinClusterPayload extends BaseFlightActionPayload {
  cabinCode: string;
}

export class RemoveClusterAction implements FlightAction<RemoveCabinClusterPayload> {
  public actionType: FlightActionType = FlightActionType.removeCluster;
  public label = t('remove_cluster');
  public value: any = null;
  public cabinCode: string;

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.removeCluster,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.removeCluster,
        field: FilterFieldField.removeCluster,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.removeCluster,
        uniqueField: this.cabinCode,
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: RemoveCabinClusterPayload): void {
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): RemoveCabinClusterPayload {
    const flightAction: RemoveCabinClusterPayload = {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
    };

    return flightAction;
  }
}
