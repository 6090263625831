import { CascaderItem } from '@/models/element-ui';
import { SlimFlightLineModel } from '@/modules/api/flight/flight-contracts';
import { FlightActionConfigurationParams } from '@/modules/flight-actions/actions/definitions/flight-action-factory';
import { FilterFieldDefinition, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { PricingTactic } from '@/modules/shared/models/PricingTacticManagement';

export interface IFlightActionService {
  applyActions(
    flightActions: FilterFieldDefinition[],
    flightLines: SlimFlightLineModel[],
    config: FlightActionConfigurationParams,
  ): Promise<any>;

  patchCollection(flightCollectionAction: FlightCollectionAction): Promise<any>;
}

export enum FlightActionType {
  changeSegmentLimit = 'update_segment_limit',
  changeMinControl = 'update_min_control',
  changeEffectiveDate = 'update_effective_date',
  changeDiscontinueDate = 'update_discontinued_date',
  revert = 'revert_to_last_capture',
  SendFlightsToPss = 'send_flights_to_pss',
  closeLowestAvailableFare = 'close_lowest_available_fare',
  setAuBelowLafToZero = 'set_au_below_laf_to_zero',
  openSetSeatsLowestAvailableFare = 'open_and_set_seats_lowest_available_fare',
  setSaOfLaf = 'set_seats_lowest_available_fare',
  increaseSaOfLaf = 'increase_seats_lowest_available_fare',
  addOptimisationProfileLevel = 'add_optimisation_profile_level',
  removeOptimisationProfileLevel = 'remove_optimisation_profile_level',
  addShadowOptimisationProfileLevel = 'add_shadow_optimisation_profile_level',
  removeShadowOptimisationProfileLevel = 'remove_shadow_optimisation_profile_level',
  swapOptimisationTactic = 'swap_optimisation_tactic',
  changeOverbookingFactor = 'change_overbooking_factor',
  changeMultiLegOverbookingFactor = 'change_multi_leg_overbooking_factor',
  applyRecommendedAU = 'apply_recommended_au',
  changeUnbalancedAdjustment = 'change_unbalanced_adjustment',
  changeMultiLegUnbalancedAdjustment = 'change_multi_leg_unbalanced_adjustment',
  changeBalancedAdjustment = 'change_balanced_adjustment',
  changeMultiLegBalancedAdjustment = 'change_multi_leg_balanced_adjustment',
  resolveByMatchingOvb = 'resolve_leftover_capacity_to_ovb',
  resolveByIgnoring = 'resolve_leftover_capacity_by_ignoring',
  moveOptimizationProfileLevel = 'move_optimisation_profile_level',
  moveShadowOptimizationProfileLevel = 'move_shadow_optimisation_profile_level',
  correctAUStructure = 'correct_au_structure',
  updateAuthorizationUnits = 'update_authorization_units',
  updateSeatAvailability = 'update_seat_availability',
  updateProtection = 'update_protection',
  tagsAdd = 'add_tags',
  tagsRemove = 'remove_tags',
  updateAutopilot = 'update_autopilot',
  updateOverbookingAutopilot = 'update_overbooking_autopilot',
  updateMultiLeggedOverbookingAutopilot = 'update_multi_legged_overbooking_autopilot',
  applyDatascienceRecommendedLaf = 'apply_datascience_recommended_laf',
  setPricingAdjustment = 'set_pricing_adjustment',
  moveTacticalAdjustmentLevel = 'move_tactical_adjustment_level',
  setPricingIncrement = 'set_pricing_increment',
  setPricingTactic = 'set_pricing_tactic',
  updateClusterLevel = 'update_cluster_level',
  moveClusterLevel = 'move_cluster_level',
  updateSourceFlight = 'update_source_flight',
  setPromotion = 'set_promotion',
  removePromotion = 'remove_promotion',
  removeCluster = 'remove_cluster',
  removeSourceFlight = 'remove_source_flight',
  addLinkedClassRule = 'add_linked_class_rule',
  removeLinkedClassRule = 'remove_linked_class_rule',
  addRivalRule = 'add_rival_rule',
  removeRivalRule = 'remove_rival_rule',
  matchCompetitor = 'set_lowest_available_fare_to_match_competitor',
  setRecommendedOverbookingRiskPercentage = 'set_recommended_overbooking_risk_percentage',
  setMultiLeggedRecommendedOverbookingRiskPercentage = 'set_multi_legged_recommended_overbooking_risk_percentage',
  applyRecommendedOverbookingFactor = 'apply_recommended_overbooking_factor',
  applyMultiLeggedRecommendedOverbookingFactor = 'apply_multi_legged_recommended_overbooking_factor',
  moveRecommendedOverbookingRiskPercentage = 'move_recommended_overbooking_risk_percentage',
}

export interface DistributedClasses {
  classes: ClassValue[];
  distribution: AvailabilityDistribution;
}

export interface ClassValue {
  code: string;
  pinned: PinState;
  value: any;
  changed?: boolean;
  clearValue?: boolean;
  discrete?: boolean;
  order?: number;
  referencedClassCode?: string;
  selectedUnit?: any[];
  showWarningMessage?: boolean;
  type?: FlightActionUpdateType;
}

export enum FlightActionUpdateType {
  AuBased = 'au_based',
  PercentageAuOfClass = 'percentage_au_of_class',
  PercentageOfScap = 'percentage_of_scap',
  PercentageOfAcap = 'percentage_of_acap',
  SaBased = 'sa_based',
  PercentageSaOfClass = 'percentage_sa_of_class',
  PrBased = 'pr_based',
  PercentagePrOfClass = 'percentage_pr_of_class',
}

export interface AdjustmentBulkUpdate {
  classCode: string;
  value: number;
  clearValue?: boolean;
}

export interface TypedAdjustmentBulkUpdate extends AdjustmentBulkUpdate {
  type: string;
  referencedClassCode: string;
}

export interface TypedPinnableAdjustmentBulkUpdate extends TypedAdjustmentBulkUpdate {
  pinState: PinState;
}

export enum AvailabilityDistribution {
  MAINTAIN_PROTECTIONS = 'maintainPr',
  DONT_MAINTAIN_PROTECTIONS = 'noMaintainPr',
  EVENLY = 'evenly',
}

export enum BoundaryType {
  NO_LIMIT = 'no_limit',
  ABSOLUTE_LIMIT = 'absolute_limit',
  CLASS_LIMIT = 'class_limit',
}

export enum MoveToCompetitorClass {
  CLOSEST = 'closest',
  HIGHER = 'higher',
  LOWER = 'lower',
}

export enum PriceAdjustmentType {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}

export enum MoveToCompetitorType {
  MINCF = 'minCf',
  MINRIVALCF = 'minRivalCf',
  CARRIER = 'carrier',
}

export enum PinState {
  PIN = 'pin',
  UNPIN = 'unpin',
  NO_CHANGE = 'noChange',
}

export interface FlightCollectionAction {
  actions: BaseFlightActionPayload[];
  ondIds: number[];
}

// TODO: naming
export interface SingleFlightCollectionAction {
  actions: FlightAction<any>[];
}
export interface BaseFlightActionPayload {
  actionType: FlightActionType;
}

export interface FlightAction<T> {
  /**
   * Label will be used for presentational purposes in select or a list of selected actions
   */
  label?: string;
  /**
   * uid is a unique string (Guid),
   * which helps Vue Identify the unique action (as a :key)
   */
  uid?: string;
  actionType: FlightActionType;
  /**
   * A lot of actions make use of the cabinCode to define to which cabin the action applies to.
   */
  cabinCode?: string;
  /**
   * getPayload gets the payload form the action,
   * which is a DTO ready to be sent to the API,
   * containing the data needed to execute the action
   */
  getPayload?: (flightLines?: SlimFlightLineModel[]) => T;
  value?: any;
  /**
   * cascaderOption holds the option that can be added to a cascader,
   * and holds all the info for the UI to display the action's editor(s)
   */
  cascaderOption?: CascaderItem<FlightActionDefinition>;
  setPayload?: (payload: T) => void;
  /**
   * setValueByDefinition sets the value by extracting it from the FlightActionDefinition
   * It's used to set the value coming from a Cascader option.
   */
  setValueByDefinition?: (action: FlightActionDefinition) => void;

  /**
   * Optional key value pairs since we don't know the type here
   */
  [key: string]: any;
}

export interface FlightClassAction<T> extends FlightAction<T> {
  cabinCode: string;
}

export interface UpdateAuthorizationUnitsPayload extends FlightAction<UpdateAuthorizationUnitsPayload> {
  cabinCode: string;
  updates: TypedPinnableAdjustmentBulkUpdate[];
}

export interface UpdateProtectionAdjustingAuthorizationUnitsPayload
  extends FlightAction<UpdateProtectionAdjustingAuthorizationUnitsPayload> {
  cabinCode: string;
  updates: TypedPinnableAdjustmentBulkUpdate[];
}

export interface UpdateSegmentLimitPayload extends FlightAction<UpdateSegmentLimitPayload> {
  cabinCode: string;
  updates: AdjustmentBulkUpdate[];
}

export interface UpdateDiscontinuedDatePayload extends FlightAction<UpdateDiscontinuedDatePayload> {
  cabinCode: string;
  updates: AdjustmentBulkUpdate[];
}

export interface UpdateEffectiveDatePayload extends FlightAction<UpdateEffectiveDatePayload> {
  cabinCode: string;
  updates: AdjustmentBulkUpdate[];
}

export interface AddOptimisationProfileLevelPayload extends FlightAction<AddOptimisationProfileLevelPayload> {
  cabinCode: string;
  optimisationProfileLevelId: string;
  applyRecommendedAU: boolean;
}

export interface RemoveOptimisationProfileLevelPayload extends FlightAction<RemoveOptimisationProfileLevelPayload> {
  cabinCode: string;
}

export interface MoveOptimisationProfileLevelPayLoad extends FlightAction<MoveOptimisationProfileLevelPayLoad> {
  cabinCode: string;
  offset: number;
  applyRecommendedAU: boolean;
}
export interface CloseLafsPayload extends FlightAction<CloseLafsPayload> {
  cabinCode: string;
  offset: number;
}

export interface SetSeatsLafsPayload extends FlightAction<SetSeatsLafsPayload> {
  cabinCode: string;
  value: number;
}

export interface SetPricingAdjustmentPayload extends FlightAction<SetPricingAdjustmentPayload> {
  cabinCode: string;
  value: number;
}

export interface SetPricingIncrementPayload extends FlightAction<SetPricingIncrementPayload> {
  cabinCode: string;
  value: number;
}

export interface SetPricingTacticPayload extends FlightAction<SetPricingTacticPayload> {
  cabinCode: string;
  pricingTactic: PricingTactic;
}

export interface RemoveCabinPromotionPayload extends FlightAction<RemoveCabinPromotionPayload> {
  cabinCode: string;
}

export interface ChangeUnbalancedAdjustmentPayload extends FlightAction<ChangeUnbalancedAdjustmentPayload> {
  cabinCode: string;
  value: number;
}

export interface ChangeOverbookingFactorPayload extends FlightAction<ChangeOverbookingFactorPayload> {
  cabinCode: string;
  value: number;
}

export interface ResolveLeftoverCapacityToOverbookingFactorActionPayLoad
  extends FlightAction<ResolveLeftoverCapacityToOverbookingFactorActionPayLoad> {
  cabinCode: string;
}

export interface ApplyRecommendedAUPayload extends FlightAction<ApplyRecommendedAUPayload> {
  cabinCode: string;
}
export interface SetAuBelowLafToZeroPayload extends FlightAction<SetAuBelowLafToZeroPayload> {
  cabinCode: string;
}
export interface UpdateClusterLevelPayLoad extends FlightAction<UpdateClusterLevelPayLoad> {
  cabinCode: string;
  clusterLevel: number;
}

export interface MoveClusterLevelPayLoad extends FlightAction<MoveClusterLevelPayLoad> {
  cabinCode: string;
  offset: number;
}

export interface RemoveCabinClusterPayload extends FlightAction<RemoveCabinClusterPayload> {
  cabinCode: string;
}
