import { storeToRefs } from 'pinia';
import { NavigationGuard } from 'vue-router';

import { Feature } from '@/modules/features/api/features/features.contracts';
import { useFeaturesStore } from '@/modules/features/store/features.store';

export const featureGuard: NavigationGuard = async (to, from, next) => {
  if (to.meta && to.meta.features) {
    const featuresStore = useFeaturesStore();
    const { features } = storeToRefs(featuresStore);
    const requiredFeatures: Feature[] = to.meta.features;

    try {
      const hasAccess: boolean = requiredFeatures.every((requiredFeature) => features.value[requiredFeature] === true);
      hasAccess ? next() : next(false);
    } catch (error) {
      next(false);
    }
  } else {
    next();
  }
};
