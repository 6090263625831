import { CabinCode } from '@/modules/api/application/application-contracts';
import { CrossFilterExtraConfigParams } from '@/modules/control/models/crossfilter/crossfilter-definitions';
import { CustomerSettingsModel, RealtimeBookingSource } from '@/modules/customer-settings/api/customer-settings.contracts';
import { FeaturesModel } from '@/modules/features';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { i18n } from '@/plugins/i18n';
import type { useAppSettingsStore } from '@/store/modules/app-settings.store';

const { t } = i18n.global;

export interface CrossfilterConfiguration {
  /**
   * We use the id to identify the widget.
   */
  id: string;
  /**
   * A widget has a default title, you can override it.
   */
  title: string;
  widgetType: CrossFilterType;
  /**
   * These params are passed to the widget instance during instantiation.
   */
  widgetParams?: CrossFilterExtraConfigParams;
  /**
   * Can be used to determine the visibility of a cross filter in the cross filter selection
   */
  filter?: boolean | ((params: CrossFilterFilterParams) => boolean);
}

interface CrossFilterFilterParams {
  appSettings: ReturnType<typeof useAppSettingsStore>;
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>;
  features: FeaturesModel;
}

const hasDynamicForecastingEnabledFilterFn: CrossfilterConfiguration['filter'] = (params) =>
  !!(params.customerSettings?.hasForecastingEnabled && params.customerSettings?.hasForecastingAndDynamicProgramEnabled);

export enum CrossFilterType {
  ancillaryShareRevenue = 'ancillaryShareRevenue',
  bookings = 'bookings',
  cabinCfDiff = 'cabinCfDiff',
  cabinForecastLoadFactor = 'cabinForecastLoadFactor',
  cabinLAF = 'cabinLAF',
  cabinOPLevel = 'cabinOPLevel',
  cabinPerformance = 'cabinPerformance',
  cabinPerformanceBandPickup7Day = 'cabinPerformanceBandPickup7Day',
  cabinPickup0Day = 'cabinPickup0Day',
  cabinPickup1Day = 'cabinPickup1Day',
  cabinPickup3Day = 'cabinPickup3Day',
  cabinPickup7Day = 'cabinPickup7Day',
  projectedPickup7Day = 'projectedPickup7Day',
  cabinRecommendedLAF = 'cabinRecommendedLAF',
  cabinRecommendedLafVariance = 'cabinRecommendedLafVariance',
  cabinRecommendedLafVarianceDataScience = 'cabinRecommendedLafVarianceDataScience',
  cabinRivalFaresDiff = 'cabinRivalFaresDiff',
  cabinSeatAvailabilityInLAF = 'cabinSeatAvailabilityInLAF',
  cabinShadowRecommendedLAF = 'cabinShadowRecommendedLAF',
  cabinShadowRecommendedLafVariance = 'cabinShadowRecommendedLafVariance',
  capacityLoadFactor = 'capacityLoadFactor',
  dayOfWeek = 'dayOfWeek',
  departureDate = 'departureDate',
  departureTime = 'departureTime',
  lastBooking = 'lastBooking',
  lastUserAction = 'lastUserAction',
  lastUserReview = 'lastUserReview',
  loadFactor = 'loadFactor',
  optimalBookings = 'optimalBookings',
  negotiatedAllocationsSold = 'negotiatedAllocationsSold',
  negotiatedAllocationsConfirmedUtilization = 'negotiatedAllocationsConfirmedUtilization',
}

/**
 * Get a list of all available crossfilter configuration objects based on some input params.
 * This list can be used to present to the user to make a selection.
 */
export function getCrossFilterConfigurations({ cabinCodes }: { cabinCodes: CabinCode[] }): CrossfilterConfiguration[] {
  const flightLevelWidgets: CrossfilterConfiguration[] = [
    {
      id: 'departureDate',
      title: t('control.cross_filters.departure_date'),
      widgetType: CrossFilterType.departureDate,
    },
    {
      id: 'departureTime',
      title: t('control.cross_filters.departure_time'),
      widgetType: CrossFilterType.departureTime,
    },
    {
      id: 'loadFactor',
      title: t('control.cross_filters.lid_load_factor'),
      widgetType: CrossFilterType.loadFactor,
    },
    {
      id: 'capacityLoadFactor',
      title: t('control.cross_filters.capacity_load_factor'),
      widgetType: CrossFilterType.capacityLoadFactor,
    },
    {
      id: 'bookings',
      title: t('control.cross_filters.bookings'),
      widgetType: CrossFilterType.bookings,
    },
    {
      id: 'lastBooking',
      title: t('control.cross_filters.days_since_last_booking'),
      widgetType: CrossFilterType.lastBooking,
    },
    {
      id: 'dayOfWeek',
      title: t('control.cross_filters.day_of_week'),
      widgetType: CrossFilterType.dayOfWeek,
    },
    {
      id: 'lastUserReview',
      title: t('control.cross_filters.days_since_last_user_review'),
      widgetType: CrossFilterType.lastUserReview,
    },
    {
      id: 'lastUserAction',
      title: t('control.cross_filters.days_since_last_user_action'),
      widgetType: CrossFilterType.lastUserAction,
    },
    {
      id: 'negoSold',
      title: t('control.cross_filters.negotiated_allocations_sold'),
      widgetType: CrossFilterType.negotiatedAllocationsSold,
      filter: (params) => !!params.customerSettings?.hasNegoAllocationEnabled && !!params.customerSettings?.hasTourOperatorsV2Enabled,
    },
    {
      id: 'negoConfirmedUtilization',
      title: t('control.cross_filters.negotiated_allocations_confirmed_utilization'),
      widgetType: CrossFilterType.negotiatedAllocationsConfirmedUtilization,
      filter: (params) => !!params.customerSettings?.hasNegoAllocationEnabled && !!params.customerSettings?.hasTourOperatorsV2Enabled,
    },
    {
      id: 'optimalFinalBookings',
      title: t('control.cross_filters.optimal_final_bookings'),
      widgetType: CrossFilterType.optimalBookings,
      filter: hasDynamicForecastingEnabledFilterFn,
    },
    {
      id: 'projectedPickup7Day',
      title: t('control.cross_filters.projected_pickup', 7),
      widgetType: CrossFilterType.projectedPickup7Day,
      widgetParams: {
        pickupDays: 7,
      },
      filter: hasDynamicForecastingEnabledFilterFn,
    },
    {
      id: 'ancillaryRevenue',
      title: t('control.cross_filters.ancillary_revenue'),
      widgetType: CrossFilterType.ancillaryShareRevenue,
      filter: (params) => !!params.customerSettings?.includeAncillaryInTotalRevenue,
    },
  ];

  const cabinCrossFilters: CrossfilterConfiguration[] =
    cabinCodes?.flatMap((cabinCode) => {
      const tmp: CrossfilterConfiguration[] = [
        {
          id: `negoSold_${cabinCode}`,
          title: t('control.cross_filters.negotiated_allocations_sold'),
          widgetType: CrossFilterType.negotiatedAllocationsSold,
          widgetParams: {
            cabinCode,
          },
          filter: (params) => !!params.customerSettings?.hasNegoAllocationEnabled && !!params.customerSettings?.hasTourOperatorsV2Enabled,
        },
        {
          id: `negoConfirmedUtilization_${cabinCode}`,
          title: t('control.cross_filters.negotiated_allocations_confirmed_utilization'),
          widgetType: CrossFilterType.negotiatedAllocationsConfirmedUtilization,
          widgetParams: {
            cabinCode,
          },
          filter: (params) => !!params.customerSettings?.hasNegoAllocationEnabled && !!params.customerSettings?.hasTourOperatorsV2Enabled,
        },
        {
          id: `cabinCfDiff_${cabinCode}`,
          title: t('control.cross_filters.cabin_cf_diff'),
          widgetType: CrossFilterType.cabinCfDiff,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `cabinRivalFaresDiff_${cabinCode}`,
          title: t('control.cross_filters.cabin_rival_fares_diff'),
          widgetType: CrossFilterType.cabinRivalFaresDiff,
          widgetParams: {
            cabinCode,
          },
        },
        // TODO: (KB) make pick up days also dynamic - See https://dev.azure.com/kambr/Eddy/_workitems/edit/11775
        {
          id: `cabinPickup1Day_${cabinCode}`,
          title: t('control.cross_filters.cabin_n_day_pickup', 1),
          widgetType: CrossFilterType.cabinPickup1Day,
          widgetParams: {
            cabinCode,
            pickupDays: 1,
          },
        },
        {
          id: `cabinPickup3Day_${cabinCode}`,
          title: t('control.cross_filters.cabin_n_day_pickup', 3),
          widgetType: CrossFilterType.cabinPickup3Day,
          widgetParams: {
            cabinCode,
            pickupDays: 3,
          },
        },
        {
          id: `cabinPickup7Day_${cabinCode}`,
          title: t('control.cross_filters.cabin_n_day_pickup', 7),
          widgetType: CrossFilterType.cabinPickup7Day,
          widgetParams: {
            cabinCode,
            pickupDays: 7,
          },
        },
        {
          id: `cabinPerformance_${cabinCode}`,
          title: t('control.cross_filters.cabin_cluster_performance'),
          widgetType: CrossFilterType.cabinPerformance,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `cabinLAF_${cabinCode}`,
          title: t('control.cross_filters.cabin_laf'),
          widgetType: CrossFilterType.cabinLAF,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `cabinSA_LAF_${cabinCode}`,
          title: t('control.cross_filters.seat_availability_lowest_available_fare'),
          widgetType: CrossFilterType.cabinSeatAvailabilityInLAF,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `cabinPerformanceBandPickup_7Days_${cabinCode}`,
          title: t('control.cross_filters.performance_band_pickup', 7),
          widgetType: CrossFilterType.cabinPerformanceBandPickup7Day,
          widgetParams: {
            cabinCode,
            pickupDays: 7,
          },
        },
        {
          id: `cabinOPLevel_${cabinCode}`,
          title: t('control.cross_filters.op_level'),
          widgetType: CrossFilterType.cabinOPLevel,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `loadFactor_${cabinCode}`,
          title: t('control.cross_filters.lid_load_factor'),
          widgetType: CrossFilterType.loadFactor,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `capacityLoadFactor_${cabinCode}`,
          title: t('control.cross_filters.capacity_load_factor'),
          widgetType: CrossFilterType.capacityLoadFactor,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `bookings_${cabinCode}`,
          title: t('control.cross_filters.bookings'),
          widgetType: CrossFilterType.bookings,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `cabinRecommendedLAF_${cabinCode}`,
          title: t('control.cross_filters.cabin_rlaf'),
          widgetType: CrossFilterType.cabinRecommendedLAF,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `cabinRecommendedLafVariance_${cabinCode}`,
          title: t('control.cross_filters.cabin_rlaf_variance'),
          widgetType: CrossFilterType.cabinRecommendedLafVariance,
          widgetParams: {
            cabinCode,
          },
        },
        {
          id: `optimalFinalBookings_${cabinCode}`,
          title: t('control.cross_filters.optimal_final_bookings'),
          widgetType: CrossFilterType.optimalBookings,
          widgetParams: {
            cabinCode,
          },
          filter: hasDynamicForecastingEnabledFilterFn,
        },
        {
          id: `projectedPickup7Day_${cabinCode}`,
          title: t('control.cross_filters.projected_pickup', 7),
          widgetType: CrossFilterType.projectedPickup7Day,
          widgetParams: {
            cabinCode,
            pickupDays: 7,
          },
          filter: hasDynamicForecastingEnabledFilterFn,
        },
        {
          id: `cabinPickup0Day_${cabinCode}`,
          title: t('control.cross_filters.cabin_realtime_pickup'),
          widgetType: CrossFilterType.cabinPickup0Day,
          widgetParams: {
            pickupDays: 0,
            cabinCode,
          },
          filter: ({ customerSettings }) =>
            customerSettings?.realtimeBookingSource === RealtimeBookingSource.STREAMED ||
            customerSettings?.realtimeBookingSource === RealtimeBookingSource.SCHEDULED,
        },
        {
          id: `cabinRecommendedLafVarianceDataScience_${cabinCode}`,
          title: t('control.cross_filters.cabin_rlaf_variance_data_science'),
          widgetType: CrossFilterType.cabinRecommendedLafVarianceDataScience,
          widgetParams: {
            cabinCode,
          },
          filter: (params) => !!params.customerSettings?.hasAirAsiaDataScienceIntegrationEnabled,
        },
        {
          id: `cabinForecastLoadFactor_${cabinCode}`,
          title: t('control.cross_filters.cabin_forecast_load_factor'),
          widgetType: CrossFilterType.cabinForecastLoadFactor,
          widgetParams: {
            cabinCode,
          },
          filter: (params) => !!params.customerSettings?.hasAirAsiaDataScienceIntegrationEnabled,
        },
        {
          id: `cabinShadowRecommendedLaf_${cabinCode}`,
          title: t('control.cross_filters.cabin_shadow_rlaf'),
          widgetType: CrossFilterType.cabinShadowRecommendedLAF,
          widgetParams: {
            cabinCode,
          },
          filter: (params) => !!params.customerSettings?.hasShadowTacticsEnabled,
        },
        {
          id: `cabinShadowRecommendedLafVariance_${cabinCode}`,
          title: t('control.cross_filters.cabin_shadow_rlaf_variance'),
          widgetType: CrossFilterType.cabinShadowRecommendedLafVariance,
          widgetParams: {
            cabinCode,
          },
          filter: (params) => !!params.customerSettings?.hasShadowTacticsEnabled,
        },
      ];

      return tmp;
    }) || [];

  return [...flightLevelWidgets, ...cabinCrossFilters];
}
