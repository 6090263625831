import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionConfigurationParams } from '@/modules/flight-actions/actions/definitions/flight-action-factory';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import {
  AvailabilityDistribution,
  BaseFlightActionPayload,
  FlightAction,
  FlightActionType,
} from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface IncreaseSeatAvailabilityLowestAvailableFareActionPayload extends BaseFlightActionPayload {
  value: number;
  cabinCode: string;
  availabilityDistribution: AvailabilityDistribution;
}

export type IncreaseSeatAvailabilityLowestAvailableFareActionValueArray = [
  // SeatsAvailable
  { value: number },
  // Distribution
  { value: AvailabilityDistribution },
];

export class IncreaseSeatAvailabilityLowestAvailableFareAction
  implements FlightAction<IncreaseSeatAvailabilityLowestAvailableFareActionPayload>
{
  public actionType: FlightActionType = FlightActionType.increaseSaOfLaf;
  public label = t('dynamic_filters.increase_sa_of_laf');
  public value = [{ value: 1 }, { value: AvailabilityDistribution.MAINTAIN_PROTECTIONS }];
  public cabinCode: string;
  public showBulkAvailabilityWarning: boolean;
  public availabilityDistribution: AvailabilityDistribution = AvailabilityDistribution.MAINTAIN_PROTECTIONS;

  constructor({ cabinCode, config }: { cabinCode?: string; config: FlightActionConfigurationParams }) {
    this.cabinCode = cabinCode;
    this.showBulkAvailabilityWarning = config.pssCapabilities.hasSegmentLimit;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.addSaOfLaf,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: FlightActionType.increaseSaOfLaf,
        field: FilterFieldField.addSaOfLaf,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.addSaOfLaf,
        uniqueField: this.cabinCode,
        value: this.value,
        availabilityDistribution: this.availabilityDistribution,
        componentName: ComponentName.AddSeatsLowestAvailableFareFilterField,
        isValueValid: (value: any) => isValueNotEmpty(value),
        warning: this.showBulkAvailabilityWarning ? t('bulk_availability_change') : null,
      },
    };
  }

  /**
   * This action has a side-effect. Initially the value is set to the SA (1 by default).
   * But when the editor is initialized, the value is set an array where the first position is the SA and the second is the distribution.
   * @param action The FlightAction Definition
   */
  public setValueByDefinition(action: FlightActionDefinition): void {
    const values = action.value as IncreaseSeatAvailabilityLowestAvailableFareActionValueArray;
    this.value[0].value = values[0].value as number;
    this.availabilityDistribution = values[1].value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: IncreaseSeatAvailabilityLowestAvailableFareActionPayload): void {
    this.value[0].value = payload.value;
    this.availabilityDistribution = payload.availabilityDistribution;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): IncreaseSeatAvailabilityLowestAvailableFareActionPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      value: this.value[0].value as number,
      availabilityDistribution: this.availabilityDistribution,
    };
  }
}
