export const en = {
  external: {
    titles: {
      login: 'Login',
    },
    info: {
      sign_in: 'A few more clicks to sign in to your account',
      manage_flights: 'Manage all your flights in one place',
      verification_code_sent: 'Verification Code has been sent',
      new_verification_code_sent: 'A fresh Verification Code has been sent',
    },
    headings: {
      sign_in: 'Sign in',
    },
    labels: {
      username: 'Email',
      password: 'Password',
      verification_code: 'Verification Code',
      phone_number: 'Phone Number',
    },
    actions: {
      log_in: 'Log In',
      verify: 'Verify',
      resend_code: 'Resend Code',
    },
    required_field: {
      email: 'Email is required',
      password: 'Password is required',
      phone_number: 'Phone Number is required',
    },
    invalid_field: {
      verification_code: 'Verification Code needs to consist of six numbers',
      phone_number: 'Phone number needs to be in format: +31612345678',
    },
    error: {
      invalid_username_password: 'Invalid email/password combination',
      invalid_verification_code: 'Verification code is either incorrect or expired',
      resend_verification_code_failed: 'Failed to send verification code',
    },
  },
};
