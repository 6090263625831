import { RemovableRef, useStorage } from '@vueuse/core';
import axios from 'axios';
import { StoreGeneric, defineStore, getActivePinia } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { AuthTokenModel, Authority, LoginRequestModel, LoginResponseModel } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { useFlightReviewStore } from '@/modules/control/store/flight-review.store';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { JwtService } from '@/services/jwt.service';
import { ControlModule } from '@/store/modules/control.module';

export const useAuthStore = defineStore('auth', () => {
  const token: RemovableRef<string | null> = useStorage('token', null);
  const isLoading: Ref<boolean> = ref(false);

  const decodedToken: ComputedRef<AuthTokenModel | null> = computed(() => (token.value ? JwtService.decodeToken(token.value) : null));
  const username: ComputedRef<string | undefined> = computed(() => decodedToken.value?.name);
  const email: ComputedRef<string | undefined> = computed(() => decodedToken.value?.user_name);
  const authorities: ComputedRef<Authority[] | undefined> = computed(() => decodedToken.value?.authorities);

  async function login(credentials: LoginRequestModel): Promise<void> {
    try {
      isLoading.value = true;

      const response: LoginResponseModel = await authService.login(credentials);
      token.value = response.access_token;
    } catch (error) {
      MessageService.failedRequest('Failed to login');
      token.value = null;
      throw error;
    } finally {
      isLoading.value = false;
    }
  }

  function logout(): void {
    ControlModule.clearSearchAndQuery();

    const activeStores = getActivePinia();

    if (activeStores) {
      // _s does exist and stores the Pinia stores, but it's internal so let's see for how long this keeps working 🤞
      // @ts-ignore-next-line
      activeStores?._s?.forEach((store: StoreGeneric) => {
        try {
          store.$reset();
        } catch (e) {
          logger.error(e as Error);
        }
      });
    }

    // $reset the flightReviewStore explicitly as it could be persisted in localstorage without a connected activeStores.
    // This way we make sure to also clear out the storage while not detaching it prom the persisted state
    const flightReviewStore = useFlightReviewStore();
    flightReviewStore.$reset();

    // Make sure requests won't have the 'invalid' token anymore
    delete axios.defaults.headers.common.Authorization;
    token.value = null;
  }

  function $reset(): void {
    token.value = null;
    isLoading.value = false;
  }

  return {
    isLoading,
    username,
    email,
    authorities,
    login,
    logout,
    token,
    $reset,
  };
});
