import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions.contracts';
import { isValueNotEmpty } from '../validation-options';

const { t } = i18n.global;

export interface ChangeSingleLegOverbookingFactorPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: number;
  applyRecommendedAU: boolean;
}

export class ChangeSingleLegOverbookingFactorAction implements FlightAction<ChangeSingleLegOverbookingFactorPayload> {
  public actionType: FlightActionType = FlightActionType.changeOverbookingFactor;
  public label = t('dynamic_filters.change_overbooking_factor');

  public value = 100;
  public cabinCode: string;
  public applyRecommendedAU = true;

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode ?? 'Y';
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.changeOverbooking,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: FlightActionType.changeOverbookingFactor,
        field: FilterFieldField.changeOverbooking,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.changeOverbooking,
        uniqueField: this.cabinCode,
        componentName: ComponentName.OverbookingFilterField,
        value: { value: this.value, applyRecommendedAU: this.applyRecommendedAU } as ChangeSingleLegOverbookingFactorPayload,
        warning: t('bulk_overbooking_change'),
        isValueValid: (value: any) => isValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const payload = action.value as ChangeSingleLegOverbookingFactorPayload;
    this.value = payload.value;
    this.applyRecommendedAU = payload.applyRecommendedAU;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: ChangeSingleLegOverbookingFactorPayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
    this.applyRecommendedAU = payload.applyRecommendedAU;
  }

  public getPayload(): ChangeSingleLegOverbookingFactorPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      applyRecommendedAU: this.applyRecommendedAU,
      value: this.value,
    };
  }
}
