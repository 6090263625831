import { orderBy, uniqBy } from 'lodash-es';

import { FilterFieldField } from '@/modules/api/shared-contracts';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { useMarketInfoStore } from '@/modules/route-management/store/market-info.store';

export enum ControlFilterType {
  departure_date_range = 'departureDateRange',
  day_of_week = 'dayOfWeek',
  tags = 'tags',
  users = 'users',
  origin_destination_hub = 'originDestinationHub',
  flightNumber = 'flightNumber',
  aircraft_type = 'aircraftType',
  route_group = 'routeGroup',
  carrier_code = 'carrierCode',
  optimization_profile = 'optimizationProfile',
  optimization_tactic = 'optimizationTactic',
  cluster = 'cluster',
  eventName = 'eventName',
  eventCluster = 'eventCluster',
}

export type fieldType = FilterFieldField.origin | FilterFieldField.destination;

export function filteredAirports(fil: fieldType, selected?: string[]): RouteModel[] {
  const marketInfoStore = useMarketInfoStore();

  const filteredField = fil === FilterFieldField.origin ? FilterFieldField.destination : FilterFieldField.origin;
  let airports: RouteModel[] = [];
  if (!selected || selected.length === 0) {
    airports = orderBy(uniqBy(marketInfoStore.routes, fil), fil);
  } else {
    selected.forEach((selection) => {
      const matches = marketInfoStore.routes.filter((airport: RouteModel) => airport[fil] === selection);
      airports.push(...matches);
    });
    airports = orderBy(uniqBy(airports, filteredField), filteredField);
  }
  return airports;
}
