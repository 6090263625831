import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface RemovePromotionPayload extends BaseFlightActionPayload {
  cabinCode: string;
}

export class RemovePromotionAction implements FlightAction<RemovePromotionPayload> {
  public actionType: FlightActionType = FlightActionType.removePromotion;
  public label = t('remove_promotion');
  public value: number;
  public cabinCode: string;

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.removePromotion,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.removePromotion,
        field: FilterFieldField.removePromotion,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.removePromotion,
        uniqueField: this.cabinCode,
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(actionDefinition: FlightActionDefinition): void {
    this.cabinCode = actionDefinition.uniqueField as string;
  }

  public setPayload(payload: RemovePromotionPayload): void {
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): RemovePromotionPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
    };
  }
}
