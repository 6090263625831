import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface SetAuBelowLafToZeroPayload extends BaseFlightActionPayload {
  cabinCode: string;
}

export class SetAuBelowLafToZeroAction implements FlightAction<SetAuBelowLafToZeroPayload> {
  public actionType: FlightActionType = FlightActionType.setAuBelowLafToZero;
  public label = t('dynamic_filters.set_below_zero');
  public value: any;
  public cabinCode: string;

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.setAuBelowLafToZero,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setAuBelowLafToZero,
        field: FilterFieldField.setAuBelowLafToZero,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.setAuBelowLafToZero,
        uniqueField: this.cabinCode,
        hasNoEditor: true,
        isValueValid: () => true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as string;
  }

  public getPayload(): SetAuBelowLafToZeroPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
    };
  }

  public setPayload(payload: SetAuBelowLafToZeroPayload) {
    this.cabinCode = payload.cabinCode;
  }
}
