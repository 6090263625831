export enum FlightFieldName {
  DepartureDate = 'departureDate',
  Origin = 'origin',
  Destination = 'destination',
  DayOfWeek = 'dayOfWeek',
  SourceFlightId = 'sourceFlightId',
  Tags = 'tags',
  LinkedClassRuleId = 'linkedClassRuleId',
  RivalRuleId = 'rivalRuleId',

  // Cabin
  Autopilot = 'autopilot',
  OptimisationTactics = 'optimisationTactics',
  OptimisationProfileLevel = 'optimisationProfileLevel',
  ShadowOptimisationTactics = 'shadowOptimisationTactics',
  ShadowOptimisationProfileLevel = 'shadowOptimisationProfileLevel',
  ClusterId = 'clusterId',
  PricingAdjustment = 'pricingAdjustment',
  PricingIncrement = 'pricingIncrement',
  PricingTactic = 'pricingTactic',
  LowestAvailableFareClass = 'lowestAvailableFareClass',
  PerformanceScore = 'performanceScore',

  // Class
  FlightId = 'flightId',
  CabinCode = 'cabinCode',
  ClassCode = 'classCode',
  FareValue = 'fareValue',
  EffectiveDate = 'effectiveDate',
  DiscontinuedDate = 'discontinuedDate',
  SegmentLimit = 'segmentLimit',
  MinControl = 'minControl',
  AuthorizationUnits = 'authorizationUnits',
  Protection = 'protection',
  MinProtection = 'minProtection',
  MaxProtection = 'maxProtection',
  IsAuthorizationUnitPinned = 'isAuthorizationUnitPinned',
  IsProtectionPinned = 'isProtectionPinned',
  Promotion = 'promotion',

  // Leg
  OverbookingFactor = 'overbookingFactor',
  AuthorizedCapacity = 'authorizedCapacity',
  overbookingRisk = 'overbookingRisk',
  recommendedOverbookingFactor = 'recommendedOverbookingFactor',
  OverbookingAutopilot = 'overbookingAutopilot',
}
